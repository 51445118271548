import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Question from "../components/Question";
import Result from "../components/Result";
import Progress from "../components/Progress";
import questionsZH from "../constants/questions";
import Types from "../components/Types";
import { FiChevronRight } from "react-icons/fi";
import { calculateScore } from "../components/Calculate.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PlaceHolder = styled.div`
  height: 800px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Tab = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => (props.active ? "#FFFFFF" : "#999999")};
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  margin: 0 10px;
  padding: 5px 10px;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #ffffff;
  }
`;

const QuestionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 350px;
  height: 60px;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px 40px;
  font-size: 18px;
  font-weight: bold;
  background: rgba(176, 224, 230, 0.3);
  color: #fff;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;

  &:hover {
  background: rgba(176, 224, 230, 0.8);
  }
}`;

const ResponsiveContainer = styled.div`
  width: 100%;
`;

function shuffleArray(array) {
  // 使用 Fisher-Yates 算法进行洗牌
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(0.3 * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const questions = shuffleArray(questionsZH);

const Home = () => {
  const [answers, setAnswers] = useState(
    questions.map((question) => ({
      type: question.type,
      options: 3,
    }))
  );
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("test");
  const [currentPage, setCurrentPage] = useState(0);
  const totalPage = Math.ceil(questions.length / 6);
  const progress = (currentPage / totalPage) * 100;
  const progressRef = useRef(null);

  useEffect(() => {
    if (selectedTab === "types") {
      // 处理 types tab 的操作
    }
  }, [selectedTab]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleAnswer = (questionIndex, answer, type) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = { type: type, options: answer };
    setAnswers(updatedAnswers);
    setActiveQuestion(questionIndex + 1);
  };

  const renderQuestions = () => {
    const startIndex = currentPage * 6;
    const endIndex = startIndex + 6;
    const pageQuestions = questions.slice(startIndex, endIndex);

    return pageQuestions.map((question, index) => (
      <QuestionDiv key={startIndex + index}>
        <Question
          key={startIndex + index}
          question={question.question}
          options={question.options}
          onAnswer={(answer) => handleAnswer(startIndex + index, answer, question.type)}
          active={startIndex + index === activeQuestion}
          setSelectedCount={setSelectedCount}
          selectedCount={selectedCount}
        />
      </QuestionDiv>
    ));
  };

  const handleNextPage = () => {
    if (currentPage === totalPage) {
      window.scrollTo({
        top: 0,
      });
    } else {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        setActiveQuestion(nextPage * 6);
        return nextPage;
      });
      if (progressRef.current) {
        progressRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  return (
    <Container>
      <TabContainer>
        <Tab active={selectedTab === "test"} onClick={() => handleTabClick("test")}>
          Test
        </Tab>
        <Tab active={selectedTab === "types"} onClick={() => handleTabClick("types")}>
          Types
        </Tab>
      </TabContainer>

      {selectedTab === "test" && (
        <>
          <Progress ref={progressRef} progress={progress} currentPage={currentPage} totalPage={totalPage} />
          <ResponsiveContainer>
            {currentPage === totalPage ? (
              <Result result={calculateScore(answers)} />
            ) : (
              <div>
                {renderQuestions()}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {currentPage < totalPage - 1 && (
                    <NextButton onClick={handleNextPage}>
                      <span>下一页</span>
                      <FiChevronRight className="arrow-icon" />
                    </NextButton>
                  )}
                  {currentPage === totalPage - 1 && (
                    <NextButton onClick={handleNextPage}>
                      <span>结果</span>
                      <FiChevronRight className="arrow-icon" />
                    </NextButton>
                  )}
                </div>
              </div>
            )}
          </ResponsiveContainer>
          <PlaceHolder></PlaceHolder>
        </>
      )}
      {selectedTab === "types" && <Types />}
    </Container>
  );
};

export default Home;
