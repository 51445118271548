import React from "react";
import { useParams } from "react-router-dom";
import { TYPES_DESCRIPTION_ZH } from "../constants/types";

const TypeDetail = () => {
  const { type } = useParams();
  const typeDescription = TYPES_DESCRIPTION_ZH[type];
  return (
    <div>
      <h2>{type}</h2>
      <p>{typeDescription}</p>
    </div>
  );
};

export default TypeDetail;
