export const TYPE_LETTER = {
  Moral: "M",
  Primitive: "P",

  Spiritual: "S",
  Carnal: "C",

  Vanilla: "V",
  Adventurous: "A",

  Recipient: "R",
  Initiator: "I",
};

export const TYPE_NAME = {
  "M/P": "Existence",
  "S/C": "Values",
  "V/A": "Attitude",
  "R/I": "Role",
};

export const TYPE_NAME_ZH = {
  "M/P": "存在",
  "S/C": "价值",
  "V/A": "态度",
  "R/I": "角色",
};

export const TYPES_NAME = {
  M: "Moral",
  P: "Primitive",
  S: "Spiritual",
  C: "Carnal",
  V: "Vanilla",
  A: "Adventurous",
  R: "Recipient",
  I: "Initiator",
};

export const TYPES_NAME_ZH = {
  M: "道德的",
  P: "原始的",
  S: "精神的",
  C: "肉欲的",
  V: "传统的",
  A: "冒险的",
  R: "接受者",
  I: "主导者",
};

export const TYPES = {
  MSVR: "MSVR",
  MSVI: "MSVI",
  MSAR: "MSAR",
  MSAI: "MSAI",
  MCVR: "MCVR",
  MCVI: "MCVI",
  MCAR: "MCAR",
  MCAI: "MCAI",
  PSVR: "PSVR",
  PSVI: "PSVI",
  PSAR: "PSAR",
  PSAI: "PSAI",
  PCVR: "PCVR",
  PCVI: "PCVI",
  PCAR: "PCAR",
  PCAI: "PCAI",
};

export const TYPES_DESCRIPTION_ZH = {
  MSVR: "MSVR性格类型的人们是一朵高山上的白云，宁静而纯洁。沉静，透明而清澈。他们对于性行为持有保守的态度，更注重精神层面的连接和亲密关系。他们愿意接受他人在性爱中的引导，通过情感和灵性的交流获得满足。",
  MSVI: "MSVI性格类型的人们是慈悲和智慧的圣雄，以温和的力量引领着性爱的旅程。他们注重内心的和谐和道德准则，对于性行为持有保守的态度，更加注重精神层面的连接，并在性爱中扮演主导的角色，以温柔和尊重的方式满足伴侣的需求。",
  MSAR: "MSAR性格类型的人们是一块路标，让人得以探索性爱的冒险旅途。他们注重内心的和谐和精神层面的连接，乐于尝试新颖的性爱方式。他们对于精神层面的满足和刺激的经历持开放态度，并享受在性行为中接受他人的引导和照顾。",
  MSAI: "MSAI性格类型的人们是引领着对方进行心理探索的导师。他们注重内心的和谐和精神层面的连接，愿意尝试非传统的性爱方式。他们在性行为中扮演主导的角色，以创新和尊重的方式满足伴侣的需求。",
  MCVR: "MCVR性格类型的人们是一处内心世界的庭院，承载着那份务实和安宁。他们静静地接纳着情感的涟漪，对于性行为持有道德和保守的态度，更注重与伴侣之间的肉体亲密关系。他们倾向于接受伴侣的引导和关怀，从中获得彼此的满足和愉悦。",
  MCVI: "MCVI性格类型的人们是战士，为道德与肉体欲望共同存在的合理性而战斗与坚守。他们高傲而坚定，保持着自己的价值观和原则。他们对于性行为持有保守而原始的态度，更注重在主导性行为中尊重道德准则。",
  MCAR: "MCAR性格类型的人们是渴望冒险和刺激的自由灵魂，对于肉体欲望和冒险的性爱方式持开放态度。他们注重内心的和谐和精神层面的连接，并乐于接受伴侣在性行为中的引导和冒险探索。",
  MCAI: "MCAI性格类型的人们是融合了道德准则和冒险精神的开拓者，以刺激和创新的方式书写着独占的性爱的冒险之旅。他们注重内心的和谐和精神层面的连接，勇敢地探索非传统的性爱方式，并在性行为中扮演主导的角色，创造出独特而令人兴奋的体验。",
  PSVR: "PSVR性格类型的人们是一本扉页上的诗歌，和风中的花朵一起舞蹈，被精神层面的能量所感染着。他们愿意接受精神上的满足并全情投入其中，更倾向于接受他人在性行为中的引导，享受精神和情感上自然的连接。",
  PSVI: "PSVI性格类型的人们是指导他人的生活教练，引领着灵性与规则的交融。他们散发着克制而磁性的光芒，对于性行为持有保守的态度，但是又绝对尊重自然。他们注重精神上的连接，并在性爱中扮演主导的角色。",
  PSAR: "PSAR性格类型的人们是一幅深邃的画作，感受着灵感的涌动。他们乐于尝试非传统的性爱方式，对于精神层面的满足和刺激的经历持开放态度，并更愿意接受性行为的自然发生。",
  PSAI: "PSAI性格类型的人们是奏鸣曲中的指挥家，带领着精神与激情的交响乐。他们渴望探索性爱的冒险与刺激，在性行为中扮演主导的角色，热烈地指挥着新颖又激动人心的音乐篇章。",
  PCVR: "PCVR性格类型的人们是冬日午后温暖的阳光，包围四周并完全让人沉浸其中，迷人而令人沉醉。他们更倾向于接受他人的激情表达和引导，享受性行为中的无尽满足。",
  PCVI: "PCVI性格型的人们是掌握着激情火焰的火舞者，引领着热情的舞蹈，但是有迹可循。他们散发着原始而吸引人的魅力，令人陶醉。他们喜欢传统的性行为方式，以果断和自信的态度主导性行为，将肉欲的力量推向极限。",
  PCAR: "PCAR人格类型的人们是无边的大海，包容万物，有时也掀起骇人的巨浪。想要充实他们几乎是不可能的，因为会被他们那股一股隐秘而强大的力量吞噬掉。他们敏锐地感知着性爱的刺激和冒险。对于尝试非传统的性爱方式持开放态度，乐于接受他人在性行为中的引导，从中获得深刻的满足。",
  PCAI: "PCAI人格类型的人们是驭风者，自然而然地就驾驭了性爱之风。他们富有创造力，乐于探索刺激和非传统的性爱方式。他们在性行为中喜欢扮演主导的角色，主动发起并引领着性爱的节奏。",
};
