import React from "react";
import styled from "styled-components";
import { TYPES } from "../constants/types";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px solid black;
  margin: 5px;
`;

const Types = () => {
  return (
    <Container>
      {[0, 1, 2, 3].map((rowIndex) => (
        <Row key={rowIndex}>
          {[0, 1, 2, 3].map((colIndex) => {
            const type = Object.values(TYPES)[rowIndex * 4 + colIndex];
            return (
              <Link to={`/type-detail/${type}`} key={colIndex}>
                <Box>{type}</Box>
              </Link>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

export default Types;
