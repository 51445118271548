import React, { useState, useRef, useEffect } from "react";
import { Link, BrowserRouter, Route, Routes } from "react-router-dom";
import Question from "./components/Question";
import Result from "./components/Result";
import Progress from "./components/Progress";
import questionsZH from "./constants/questions";
import Types from "./components/Types";
import styled, { createGlobalStyle } from "styled-components";
import { FiChevronRight } from "react-icons/fi";
import { calculateScore } from "./components/Calculate.js";
import Home from "./pages/Home";
import TypeDetail from "./pages/TypeDetail";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #111111;
    font-family: 'Lato', sans-serif;
    color: #FFFFFF;
    @media screen and (max-width: 450px) {
      font-size: 12px; /* 在宽度大于等于 450px 时设置中等字体大小 */
    }
    @media screen and (min-width: 450px) and (max-width: 768px) {
      font-size: 14px; /* 在宽度大于等于 1200px 时设置更大的字体大小 */
    }
    @media screen and (min-width: 768px) {
      font-size: 16px; /* 在宽度大于等于 1200px 时设置更大的字体大小 */
    }
  }
`;

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const App = () => {
  return (
    <AppContainer>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/type-detail/:type" element={<TypeDetail />} />
      </Routes>
    </AppContainer>
  );
};

export default App;
