import React from "react";
import styled from "styled-components";

const ProgressWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ currentPage, totalPage }) =>
    currentPage === 0 || currentPage === totalPage ? "hidden" : "visible"};
`;

const ProgressContainer = styled.div`
  width: 70%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background: #ffc0cb;
  transition: width 0.3s ease-in-out;
`;

const ProgressText = styled.span`
  color: white;
  margin-right: 10px;
`;

const Progress = React.forwardRef(
  ({ progress, currentPage, totalPage }, ref) => (
    <ProgressWrapper ref={ref} currentPage={currentPage} totalPage={totalPage}>
      <ProgressText>{`${progress}%`}</ProgressText>
      <ProgressContainer>
        <ProgressBar progress={progress} />
      </ProgressContainer>
    </ProgressWrapper>
  )
);

export default Progress;
