import { TYPE_LETTER } from "./types";

const option = [6, 5, 4, 3, 2, 1, 0];

const QUESTIONS_M_ZH = [
  "忠诚是关系里最重要的",
  "您认为酒后乱性是种借口吗",
  "如果好感对象有伴侣，即使非常喜欢也不能过多接触",
  "除非确定了伴侣关系，否则不能发生性行为",
  "如果一个人是第三者，您不认为你们是朋友",
  "不能和自己的好朋友发生性行为",
  "人如果不能控制自己的下半身那么将和动物没有区别",
  "您无法接受不是伴侣的唯一对象",
];
const QUESTIONS_P_ZH = [
  "出轨是一件难以控制的事情",
  "付费性行为是可以接受的",
  "即使有负面后果也很难停止不良关系",
  "一夜情是令人兴奋的",
  "肉体出轨比精神出轨更严重",
  "伴侣需要忠诚于对方只是人类社会的谎言",
  "性是本能这一点上人和动物是一样的",
];

const QUESTIONS_S_ZH = [
  "两个灵魂的融合是感情的关键",
  "人们在发生性关系之前至少应该成为朋友",
  "在伴侣的陪伴下您感到安全",
  "当伴侣吐露心声时会感觉你们更相爱了",
  "心情不好是可以拒绝性行为的理由",
  "当您想与伴侣建立情感联系时，曾遇到困难",
  "相比起入侵式的性行为，您更喜欢亲亲抱抱",
  "正式开始前，您总是喜欢有充足的前戏和爱抚",
];
const QUESTIONS_C_ZH = [
  "有可能享受与一个人的性爱，但不太喜欢那个人",
  "性只是一种身体上的事情",
  "两个深爱的人之间的性接触是人类终极的互动",
  "身体上的亲密对于持久的关系至关重要",
  "性是一种将两个相爱的人连接得更紧密的方法",
  "会常常用“性”相关的形容词来形容与性无关的事物",
  "只有通过实践才能获得性知识",
];
const QUESTIONS_V_ZH = [
  "您并不觉得自己是个追求刺激的人",
  "任何有风险的性行为都是不可接受的",
  "录制性爱视频是不可以接受的",
  "您更喜欢基本的姿势",
  "在熟悉的地方进行性行为更让您感到舒适",
  "规律的、有计划的性行为比突然发生的性行为更让人接受",
  "多人参与的性行为是无法理解的",
  "安全比兴奋更重要",
];
const QUESTIONS_A_ZH = [
  "丰富的婚前性经历是可以的",
  "有时您的性行为会危及您的健康或安全",
  "您需要更多的变化才能获得同样的兴奋",
  "相比在房间里，您更喜欢在阳台和伴侣进行性行为",
  "角色扮演很有趣",
  "您尝试或者向往SM行为",
  "您喜欢尝试各种具有新鲜感的性行为",
];
const QUESTIONS_R_ZH = [
  "您总是希望对方主动发起邀请",
  "如果对方没有主动亲吻您，您不会主动亲吻对方",
  "主动索爱让您感到难为情",
  "即使渴望亲密接触，您也不会自己提出",
  "如果对方主动亲近您,您会感到很高兴",
  "您从未向对方分享您从别处摄取到的性知识",
  "对方等着您主动的行为会让您感到生气",
  "您不爱主动提起性相关的话题",
];
const QUESTIONS_I_ZH = [
  "性的主要目的是自我享受",
  "您向伴侣表达您的感受和需求",
  "当性是从对方那里获取快乐时，性会更好",
  "自己对对方的拥有权力或权威时，性总是更好",
  "主动索爱让您感到兴奋",
  "您愿意为了双方的愉快体验付出努力",
  "您喜欢看到对方害羞的样子",
];

const questionsZH = [
  {
    question: QUESTIONS_M_ZH[0],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[1],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[2],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[3],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[4],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[5],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[6],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_M_ZH[7],
    type: TYPE_LETTER.Moral,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[0],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[1],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[2],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[3],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[4],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[5],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_P_ZH[6],
    type: TYPE_LETTER.Primitive,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[0],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[1],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[2],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[3],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[4],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[5],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[6],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_S_ZH[7],
    type: TYPE_LETTER.Spiritual,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[0],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[1],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[2],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[3],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[4],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[5],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_C_ZH[6],
    type: TYPE_LETTER.Carnal,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[0],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[1],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[2],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[3],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[4],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[5],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[6],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_V_ZH[7],
    type: TYPE_LETTER.Vanilla,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[0],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[1],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[2],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[3],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[4],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[5],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_A_ZH[6],
    type: TYPE_LETTER.Adventurous,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[0],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[1],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[2],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[3],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[4],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[5],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[6],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_R_ZH[7],
    type: TYPE_LETTER.Recipient,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[0],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[1],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[2],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[3],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[4],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[5],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
  {
    question: QUESTIONS_I_ZH[6],
    type: TYPE_LETTER.Initiator,
    options: option,
  },
];

export default questionsZH;
