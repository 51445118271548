export function calculateScore(answers) {
  const scoreMap = {
    M: 0,
    P: 0,
    S: 0,
    C: 0,
    V: 0,
    A: 0,
    R: 0,
    I: 0,
  };

  answers.forEach((answer) => {
    const { type, options } = answer;
    if (type in scoreMap) {
      scoreMap[type] += options;
    }
  });

  const calculatePercentage = (score1, score2) =>
    Math.round((score1 / (score1 + score2)) * 100);

  const calculateResult = (type1, type2) => {
    const percentage =
      scoreMap[type1] > scoreMap[type2]
        ? calculatePercentage(scoreMap[type1], scoreMap[type2])
        : calculatePercentage(scoreMap[type2], scoreMap[type1]);

    return {
      type: scoreMap[type1] > scoreMap[type2] ? type1 : type2,
      percentage,
    };
  };

  const result = {
    "M/P": calculateResult("M", "P"),
    "S/C": calculateResult("S", "C"),
    "V/A": calculateResult("V", "A"),
    "R/I": calculateResult("R", "I"),
  };

  return result;
}
