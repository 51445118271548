import React, { useCallback } from "react";
import { TYPE_NAME_ZH, TYPES_NAME_ZH, TYPES_NAME, TYPES_DESCRIPTION_ZH } from "../constants/types";
import { Link } from "react-router-dom";

const Result = ({ result }) => {
  const getType = (data) => {
    let typeString = "";
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const type = data[key].type;
        typeString += type;
      }
    }
    return typeString;
  };

  const type = useCallback(() => {
    return getType(result);
  }, [result]);

  return (
    <div>
      <h2>SOTI Result</h2>
      <Link to={`/type-detail/${type()}`}>
        <h2>您是{type()}</h2>
      </Link>

      <p>{TYPES_DESCRIPTION_ZH[type()]}</p>
      <h3>各种类型的百分比如下：</h3>
      <p>
        {TYPE_NAME_ZH["M/P"]}:{TYPES_NAME_ZH[result["M/P"].type]}-{TYPES_NAME[result["M/P"].type]}:{result["M/P"].percentage}%
      </p>
      <p>
        {TYPE_NAME_ZH["S/C"]}:{TYPES_NAME_ZH[result["S/C"].type]}-{TYPES_NAME[result["S/C"].type]}:{result["S/C"].percentage}%
      </p>
      <p>
        {TYPE_NAME_ZH["V/A"]}:{TYPES_NAME_ZH[result["V/A"].type]}-{TYPES_NAME[result["V/A"].type]}:{result["V/A"].percentage}%
      </p>
      <p>
        {TYPE_NAME_ZH["R/I"]}:{TYPES_NAME_ZH[result["R/I"].type]}-{TYPES_NAME[result["R/I"].type]}:{result["R/I"].percentage}%
      </p>
    </div>
  );
};

export default Result;
