import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const QuestionContainer = styled.div`
  height: 220px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-style: none none solid none;

  width: 70%;
  @media screen and (max-width: 518px) {
    width: 100%;
  }
  opacity: ${({ active }) => (active ? "1" : "0.3")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const QuestionHeading = styled.h2`
  text-align: center;
  margin: 0 auto; /* 使用 auto 来水平居中 */
  width: 100%; /* 设置宽度为100% */
`;

const RadioContainer = styled.div`
  margin: 0 auto;
`;

const RadioDiv = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RadioLabel = styled.label`
  display: inline-block;
  position: relative;
  user-select: none;
  text-align: center;
  color: white;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const sizeMap518 = [
  40, 32, 24, 20, 24, 32, 40,
]; /* 在宽度小于等于 450px 时设置圈圈大小 */
const sizeMap768 = [
  48, 40, 32, 24, 32, 40, 48,
]; /* 在宽度小于等于 768px 时设置圈圈大小 */
const sizeMap1200 = [
  55, 45, 35, 28, 35, 45, 55,
]; /* 在宽度大于 768px 时设置圈圈大小 */
const RadioCustomIndicator = styled.span`
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 518px) {
    height: ${({ index }) => sizeMap518[index]}px;
    width: ${({ index }) => sizeMap518[index]}px;
    border: 1px solid;
    border-color: ${({ index }) =>
      index < 3 ? "#FFC0CB" : index === 3 ? "#FFFFFF" : "#B0E0E6"};

    margin: 10px;
  }
  @media screen and (min-width: 518px) and (max-width: 768px) {
    height: ${({ index }) => sizeMap768[index]}px;
    width: ${({ index }) => sizeMap768[index]}px;
    border: 3px solid;
    border-color: ${({ index }) =>
      index < 3 ? "#FFC0CB" : index === 3 ? "#FFFFFF" : "#B0E0E6"};

    margin: 6px;
  }
  @media screen and (min-width: 768px) {
    height: ${({ index }) => sizeMap1200[index]}px;
    width: ${({ index }) => sizeMap1200[index]}px;
    border: 3px solid;
    border-color: ${({ index }) =>
      index < 3 ? "#FFC0CB" : index === 3 ? "#FFFFFF" : "#B0E0E6"};

    margin: 15px;
  }
  border-radius: 50%;
  border: 3px solid;
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ index, selected }) =>
    selected
      ? `${index < 3 ? "#FFC0CB" : index === 3 ? "#FFFFFF" : "#B0E0E6"}`
      : "transparent"};
  cursor: pointer;

  ${RadioLabel}:hover & {
    background-color: ${({ index }) =>
      index < 3 ? "#FFC0CB" : index === 3 ? "#FFFFFF" : "#B0E0E6"};
  }
`;
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const TextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AgreeText = styled.span`
  color: #ffc0cb;
  font-weight: bold;
`;

const DisagreeText = styled.span`
  color: #b0e0e6;
  font-weight: bold;
`;

const Question = ({
  question,
  options,
  onAnswer,
  active,
  setSelectedCount,
  selectedCount,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const questionRef = useRef();

  useEffect(() => {
    if (selectedOption != null) {
      scrollToNextQuestion();
    }
  }, [selectedOption]);

  const scrollToNextQuestion = () => {
    const questionContainer = questionRef.current;

    if (questionContainer) {
      const boundingRect = questionContainer.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + boundingRect.height, // You can adjust the value if needed
        behavior: "smooth",
      });
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onAnswer(option);
    setSelectedCount(selectedCount + 1);
  };

  return (
    <QuestionContainer active={active} ref={questionRef}>
      <QuestionHeading>{question}</QuestionHeading>
      <Container>
        <TextDiv>
          <AgreeText>{`同意`}</AgreeText>
        </TextDiv>
        <RadioContainer>
          <RadioDiv>
            {options.map((option, index) => (
              <RadioLabel key={index}>
                <RadioInput
                  type="radio"
                  name="option"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => handleOptionSelect(option)}
                />
                <RadioCustomIndicator
                  index={index}
                  selected={selectedOption === option}
                />
              </RadioLabel>
            ))}
          </RadioDiv>
        </RadioContainer>
        <TextDiv>
          <DisagreeText>{"反对"}</DisagreeText>
        </TextDiv>
      </Container>
    </QuestionContainer>
  );
};

export default Question;
